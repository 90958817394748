export enum AccessType {
  PUBLISH = 'Publish',
  DESIGN_TOOLS = 'Design tools',
  MEMBERLISTS = 'Memberlists',
  SMS_EMAIL = 'SMS & Email',
  KI_CHAT = 'KI Chat',
}

export type AccessTypeReturn = {
  orgMatch: {
    orgId: string;
    orgCatId: number;
    orgName: string;
  } | null;
  roleMatch: {
    roleId: number;
    roleName: string;
    orgId: string;
    orgCatId: number;
    orgName: string;
  } | null;
  access: AccessType[];
};
