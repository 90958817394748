import { AccessTypeReturn } from '@fink/dto';
import { AccessType } from '@fink/dto/dtos/accessType.dto.ts';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';

export type UserContextPerson = {
  firstName: string;
};

const UserContext = createContext<{
  person: UserContextPerson | undefined;
  hasPublishAccess: boolean;
  detailedAccess: AccessTypeReturn[] | [];
  selectedDetailedAccessIndex: number | undefined;
  setSelectedDetailedAccessIndex: Dispatch<SetStateAction<number | undefined>>;
  changeAccessCallback: (value: number) => void;
  memberCareIdAccess: string | undefined;
}>({
  person: undefined,
  hasPublishAccess: false,
  detailedAccess: [],
  selectedDetailedAccessIndex: undefined,
  setSelectedDetailedAccessIndex: () => {},
  changeAccessCallback: () => {},
  memberCareIdAccess: undefined,
});

function UserContextProvider({
  children,
  person,
  detailedAccess,
}: {
  children: React.ReactNode;
  person: UserContextPerson | undefined;
  detailedAccess: AccessTypeReturn[] | [];
}) {
  const getMemberCareIdAccess = (
    overrideAccessPoint?: AccessTypeReturn,
  ): string | undefined => {
    let initMemberCareIdAccess: string | undefined = undefined;
    if (
      detailedAccess?.length > 0 &&
      selectedDetailedAccessIndex !== undefined
    ) {
      const accessPoint =
        overrideAccessPoint || detailedAccess[selectedDetailedAccessIndex];
      if (accessPoint?.roleMatch?.orgId) {
        initMemberCareIdAccess = accessPoint?.roleMatch?.orgId;
      } else if (accessPoint?.orgMatch?.orgId) {
        initMemberCareIdAccess = accessPoint?.orgMatch?.orgId;
      }
    }
    return initMemberCareIdAccess;
  };

  const getHasPublishAccess = (
    overrideAccessPoint?: AccessTypeReturn,
  ): boolean => {
    let hasAccess = false;
    if (
      detailedAccess?.length > 0 &&
      selectedDetailedAccessIndex !== undefined
    ) {
      const accessPoint =
        overrideAccessPoint || detailedAccess[selectedDetailedAccessIndex];
      if (accessPoint?.access?.length > 0) {
        hasAccess = accessPoint.access.some((access) =>
          [AccessType.PUBLISH].includes(access),
        );
      }
    }
    return hasAccess;
  };

  const preformSetActions = (newAccessIndex: number) => {
    const newAccessPoint = detailedAccess[newAccessIndex];
    const newAccessId = getMemberCareIdAccess(newAccessPoint);
    if (newAccessId && memberCareIdAccess !== newAccessId) {
      setMemberCareIdAccess(newAccessId);
    }

    const newHasAccess = getHasPublishAccess(newAccessPoint);
    if (hasPublishAccess !== newHasAccess) {
      setHasPublishAccess(newHasAccess);
    }
  };

  const [selectedDetailedAccessIndex, setSelectedDetailedAccessIndex] =
    useState<number | undefined>(
      detailedAccess?.length > 0 ? 0 : () => undefined,
    );
  const [memberCareIdAccess, setMemberCareIdAccess] = useState<
    string | undefined
  >(getMemberCareIdAccess());
  const [hasPublishAccess, setHasPublishAccess] = useState<boolean>(
    getHasPublishAccess(),
  );

  const changeAccessCallback = (number: number): void => {
    setSelectedDetailedAccessIndex(number);
    preformSetActions(number);
  };

  return (
    <UserContext.Provider
      value={{
        person,
        hasPublishAccess,
        detailedAccess,
        selectedDetailedAccessIndex,
        setSelectedDetailedAccessIndex,
        changeAccessCallback,
        memberCareIdAccess,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

function useUserContext() {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }
  return context;
}

export { UserContextProvider, useUserContext };
